import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssesmentsComponent } from './components/assesments/assesments.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { CreateDoctorAppointmentComponent } from './components/create-doctor-appointment/create-doctor-appointment.component';
import { CreateDoctorConnectionComponent } from './components/create-doctor-connection/create-doctor-connection.component';
import { CreateRecommendationComponent } from './components/create-recommendation/create-recommendation.component';
import { DailyActivitiesComponent } from './components/daily-activities/daily-activities.component';
import { DoctorApiConnectionComponent } from './components/doctor-api-connection/doctor-api-connection.component';
import { DoctorAppointmentComponent } from './components/doctor-appointment/doctor-appointment.component';
import { DoctorHomepageComponent } from './components/doctor-homepage/doctor-homepage.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { LoginComponent } from './components/login/login.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { RecommandationsComponent } from './components/recommandations/recommandations.component';
import { RegisterComponent } from './components/register/register.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AuthGuard } from './services/auth.guard';
import { MyClientDetailsComponent } from './components/my-client-details/my-client-details.component';

const routes: Routes = [
    {
        path: '',
        component: NavbarComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                component: DoctorHomepageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'doctor-homepage',
                component: DoctorHomepageComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'assessment',
                component: AssesmentsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'daily-activities',
                component: DailyActivitiesComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'recommendations',
                component: RecommandationsComponent,
                canActivate: [AuthGuard],
            },
            {
                path: 'create-recommendation',
                component: CreateRecommendationComponent,
                canActivate: [AuthGuard]

            },
            {
                path: 'doctor-connection',
                component: DoctorApiConnectionComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'my-client-details',
                component: MyClientDetailsComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'doctor-appointment',
                component: DoctorAppointmentComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'doctor-create-appointment',
                component: CreateDoctorAppointmentComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'doctor-create-connection',
                component: CreateDoctorConnectionComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'profile',
                component: UserProfileComponent,
                canActivate: [AuthGuard]
            },
            {
                path: 'privacy-policy',
                component: PrivacyPolicyComponent,

            },
            {
                path: 'change-password',
                component: ChangePasswordComponent,
            }
        ]
    },
    {
        path: 'register',
        component: RegisterComponent,
    },
    {
        path: 'login',
        component: LoginComponent,
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule],
    providers: [AuthGuard]
})
export class AppRoutingModule { }
