import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { Router } from "@angular/router";
import { take } from "rxjs/operators";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";
import { Location } from '@angular/common';


@Component({
    selector: 'app-assesments',
    templateUrl: './assesments.component.html',
    styleUrls: ['./assesments.component.scss']
})
export class AssesmentsComponent implements OnInit {

    public date = new FormControl(new Date());
    public base64Image: string = '';
    public dataCollected: any = [];
    public base: any | undefined;
    public remembermeId: any;
    public testName: string | undefined = '';
    public range = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });

    constructor(public dataService: DataService,
        private authService: AuthService,
        private sanitizer: DomSanitizer,
        public router: Router,
        public datepipe: DatePipe,
        private location: Location) {
    }

    ngOnInit(): void {
        this.setTestName();

    }

    setTestName() {
        switch (this.dataService.assesmentType) {
            case 'RMCAS':
                this.testName = "COGNITION"
                break;
            case 'GDS':
                this.testName = "MOOD"
                break;
            case 'Personal Wellbeing':
                this.testName = "WELLBEING"
                break;
            case 'Sleep quality scale':
                this.testName = "SLEEP QUALITY SCALE"
                break;
            case 'De Jong Gierveld Loneliness':
                this.testName = "SOCIALIZATION"
                break;
            case 'RAPA':
            this.testName = "RAPA"
            break;
            default:
                break;
        }
    }

    getDataByRemembeId() {
        this.remembermeId = this.dataService.sharedUidValue;
        let startDate ='2023-01-01';
        const currentDate = new Date(); // Get the current date
        let endDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
        switch (this.dataService.assesmentType) {
            case 'RMCAS':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "RMCAS", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            case 'GDS':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "GDS", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            case 'Personal Wellbeing':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "Personal Wellbeing", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            case 'Sleep quality scale':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "Sleep quality scale", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            case 'De Jong Gierveld Loneliness':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "De Jong Gierveld Loneliness", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            case 'RAPA':
                this.dataService.getAssesmentDataByRemembeId(this.remembermeId, "RAPA", startDate, endDate).pipe(take(1)).subscribe(data => {
                    this.dataCollected = data;
                });
                break;
            default:
                break;
        }
    }

    transform(data: any) {
        this.base64Image = 'data:image/png;base64,' + data;
        return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
    }

    isBase64Image(imageData: any): boolean {
        this.base64Image = 'data:image/png;base64,' + imageData;
        const regex = /^data:image\/(png|jpg|jpeg|gif);base64,/;
        return regex.test(this.base64Image);
    }

    logout() {
        this.authService.logout();
    }

    goBack(): void {
        this.location.back();
    }

    goTo(url: string) {
        this.router.navigateByUrl(url);
    }

}
