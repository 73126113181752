import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface dialog {
  description: string;
  duration: number;
}

@Component({
  selector: 'app-dialog-error',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<DialogComponent>,
    @Inject(MAT_DIALOG_DATA) public dialog: dialog
  ) {}

  ngOnInit(): void {
    if (this.dialog.duration) {
      setTimeout(() => {
        this.closeDialogRef();
      }, this.dialog.duration);
    }
  }

  closeDialogRef(): void {
    this.dialogRef.close();
  }
}
