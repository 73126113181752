import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ISentAppointmentData, ISentConnectionData } from '../models/enum';
import { map } from 'rxjs/operators';
import { IResponse } from '../models/response';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/dialog/dialog.component';


const API_URL = "https://development-rememberme.artofinfo.eu/api/";
const API_URL_DSRL = "https://dsrl.eu:3080/dataanalysis/";
const headers = {
  Authorization: 'Bearer my-token',
  'My-Custom-Header': 'foobar',
};
@Injectable({
  providedIn: 'root'
})
export class DataService {

  sharedUidValue: string | undefined;
  sharedNickName: string | undefined;
  assesmentType: string | undefined;
  constructor(private http: HttpClient, private dialog: MatDialog) { }

  getConnections(): Observable<any> {
    let api = API_URL + "connection";
    return this.http.get(api, { headers: headers }).pipe(
      map((res) => {
        return res || {};
      }),
    );
  }

  getAppointments(): Observable<any> {
    let api = API_URL + "appointment/future";
    return this.http.get(api, { headers: headers }).pipe(
      map((res) => {
        return res || {};
      }),
    );
  }

  getAllTheScoresRegistered(remembermeId: string | undefined, startDate: any, endDate: any) {
    return this.http.get(
      API_URL_DSRL + "daily/get-values-rememberme-id/" + remembermeId + "/" + startDate + "/" + endDate
    );
  }

  getAssesmentDataByRemembeId(remembermeId: string, assessmentType: string, startDate: any, endDate: any) {
    return this.http.get(
      API_URL_DSRL + "assessment/get-by-rememberme-id/" + remembermeId + "/" + assessmentType + "/" + startDate + "/" + endDate
    );
  }

  getLatestScore(remembermeId: string | undefined, assessmentType: string) {
    return this.http.get(
      API_URL_DSRL + "assessment/get-latest-score/" + remembermeId + "/" + assessmentType
    );
  }

  getRecommaendationDataByRemembeId(remembermeId: string, startDate: any, endDate: any) {
    return this.http.get(
      API_URL_DSRL + "recommendation/get/" + remembermeId + "/" + startDate + "/" + endDate
    );
  }

  getPrivacyPolicyData(lang: string) {
    return this.http.get(
      API_URL + "document/privacy-policy?language=" + lang, { responseType: 'text' }
    );
  }

  userData() {
    return this.http.get(
      API_URL + "user"
    );
  }

  getDataTypes() {
    return this.http.get(
      API_URL_DSRL + "assessment/get-types"
    );
  }

  getAllActivityTypes() {
    return this.http.get(
      API_URL_DSRL + "daily/get-all-activity-types"
    );
  }

  getMeasurementTypes(activityType: string) {
    return this.http.get(
      API_URL_DSRL + "daily/get-all-measurement-types/" + activityType
    );
  }

  getDetailedDailyActivities(fitbitId: string, startTime: string, endTime: string) {
    return this.http.get(
      API_URL_DSRL + "daily/get-values-fitbit-id/detailed/" + fitbitId + "/" + startTime + "/" + endTime
    );
  }

  get(assessmentType: string) {
    return this.http.get(
      API_URL_DSRL + "assessment/get-all-measurement-types/sleep/" + "/" + assessmentType
    );
  }

  fitbitActivity(remembermeId: string, startTime: any, endTime: any) {
    return this.http.get(
      API_URL_DSRL + "get-values-rememberme-id/detailed/" + remembermeId + "/" + startTime + "/" + endTime

    );
  }

  postAssessment(data: any) {
    return this.http.post(
      API_URL_DSRL + "assessment/", data
    );
  }

  updateProfile(form: any) {
    const body = form;
    this.http.put<any>(API_URL + 'user', body, { headers, observe: 'response' }).subscribe((data) => {
      if (data.status === 204) {
        this.dialog.open(DialogComponent, {
          data: {
            description: "PROFILE UPDATED!",
            duration: 2000,

          }
        });
      }
    });
  }

  doctorApi() {
    return this.http.get(
      API_URL + "connection"
    );
  }

  getPublicContent(): Observable<any> {
    return this.http.get(API_URL + 'connection', { responseType: 'text' });
  }

  createAppointmentAPI(createAppointment: ISentAppointmentData): Observable<IResponse> {
    return this.http.post<ISentAppointmentData>(API_URL + 'appointment', createAppointment, { headers });
  }

  createConnectionAPI(createConnection: ISentConnectionData): Observable<IResponse> {
    return this.http.post<ISentConnectionData>(API_URL + 'connection/new-by-email-or-phone', createConnection, { headers });
  }

  createRecommendationAPI(recommendation: any) {
    const body = recommendation;
    return this.http.post<any>(API_URL_DSRL + 'recommendation/', body, { headers });
  }

}
