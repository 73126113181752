<div class="content">
  <div class="logo">
    <img src="assets/images/Logo-transparent.png" />
  </div>
  <div class="container">
    <div class="title">
      <h1>FORGOT PASSWORD?</h1>
      <div class="subtitle">
        No worries, we will generate a new password and send it by email
      </div>
    </div>
    <form
      class="send-email-area"
      [formGroup]="emailVerification"
      (ngSubmit)="sendEmailVerification()"
    >
      <div class="field">
        <label>Email</label>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Type...</mat-label>
        <input
          type="text"
          formControlName="email"
          placeholder="Enter Email"
          name="email"
          id="email"
          matInput
        />
        <mat-error *ngIf="this.emailVerification.invalid"
          >Please type your Email!
        </mat-error>
      </mat-form-field>
      <button type="submit" class="sendEmailBtn"><p>SEND EMAIL</p></button>
      <div class="back" (click)="goTo(navigationPaths.Login)">
        <mat-icon>arrow_back</mat-icon>
        <div class="text">BACK TO LOG IN</div>
      </div>
    </form>
  </div>
</div>
