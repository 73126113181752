<div class="main-container">
  <div class="navigation">
    <img src="assets/images/Logo-transparent.png" />
    <div class="menu-links">
      <div class="left">
        <a
          [routerLink]="[navigationPaths.DoctorHomepageComponent]"
          [routerLinkActive]="['active']"
        >
          HOME
        </a>
        <a
          [routerLink]="[navigationPaths.DoctorConnectionList]"
          [routerLinkActive]="['active']"
        >
          MY CLIENTS
        </a>
        <a
          [routerLink]="[navigationPaths.DoctorAppointmentList]"
          [routerLinkActive]="['active']"
        >
          MY APPOINTMENTS
        </a>
      </div>
      <div class="right">
        <a [routerLink]="[navigationPaths.Notifications]"> NOTIFICATIONS </a>
        <a
          class="user-profile"
          [routerLink]="[navigationPaths.UserProfile]"
          [routerLinkActive]="['active']"
        >
          PROFILE
        </a>
        <a (click)="logout()" class="logout"> LOGOUT </a>
      </div>
    </div>
  </div>
  <div class="line"></div>
  <img class="background-purple-img" src="assets/images/normal_u24.svg"/>
  <img class="background-blue-img" src="assets/images/normal_u32.svg"/>
  <div class="container">
    <router-outlet></router-outlet>
  </div>
</div>

<app-footer></app-footer>
