import { Pipe, PipeTransform, Component, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { DataService } from "src/app/services/data.service";


@Pipe({ name: "safeHtml" })
export class SafeHtmlPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }

  transform(value: any) {
    return this.sanitizer.bypassSecurityTrustHtml(value);
  }
}
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})

export class PrivacyPolicyComponent implements OnInit {
  @ViewChild("content", { static: false }) content: any;
  lang: string = 'EN'; // static until we find out how the language will be set
  constructor(private dataService: DataService) { }

  ngOnInit(): void {
    this.dataService.getPrivacyPolicyData(this.lang).subscribe((res) => {
      this.content = res;
    });
  }
}
