import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { ISentChangePassword, LoginModel, RegisterModel } from '../models/enum';
import { DataService } from './data.service';
import { IResponse } from '../models/response';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/dialog/dialog.component';

@Injectable()
export class AuthService {
  public userUid: string = '';
  private userLoggedInStatus: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);
  public isUserLoggedInObs = this.userLoggedInStatus.asObservable();
  public authErrorMessage: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  message: string = '';
  endpoint: string = 'https://development-rememberme.artofinfo.eu/api/';
  headers = new HttpHeaders().set('Content-Type', 'application/json');
  currentUser = {};

  constructor(
    private auth: AngularFireAuth,
    private router: Router,
    private http: HttpClient,
    private dataService: DataService,
    private dialog: MatDialog
  ) {}

  signUp(user: RegisterModel) {
    let api = `${this.endpoint}register`;
    return this.http
      .post<any>(api, user)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .subscribe(() => {
        this.dialog.open(DialogComponent, {
          data: {
            description: "Your account has been successfully created!",
            duration: 2000
          }
        });
        this.router.navigate(['login']);
      });
  }
  signIn(user: LoginModel) {
    return this.http
      .post<any>(`${this.endpoint}login`, user)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        })
      )
      .subscribe((res: any) => {
        localStorage.setItem('access_token', res.token);
        this.dataService.getConnections().subscribe((res: any) => {
          this.currentUser = res;
          this.router.navigate(['doctor-homepage']);
        });
      });
  }
  getToken() {
    return localStorage.getItem('access_token');
  }
  get isLoggedIn(): boolean {
    let authToken = localStorage.getItem('access_token');
    return authToken !== null ? true : false;
  }
  doLogout() {
    let removeToken = localStorage.removeItem('access_token');
    if (removeToken == null) {
      this.router.navigate(['login']);
    }
  }

  // Error
  handleError(error: HttpErrorResponse) {
    let msg = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      msg = error.error.message;
    } else {
      // server-side error
      msg = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    return throwError(error);
  }

  forgotPassword(form: any): Observable<IResponse> {
    const headers = {
      Authorization: 'Bearer my-token',
      'My-Custom-Header': 'foobar',
    };
    const body = form;
    return this.http.post<any>(
      'https://development-rememberme.artofinfo.eu/api/forgot-password',
      body,
      { headers }
    );
  }

  changePassword(form: ISentChangePassword): Observable<IResponse> {
    const headers = {
      Authorization: 'Bearer my-token',
      'My-Custom-Header': 'foobar',
    };
    const body = form;
    return this.http.post<ISentChangePassword>(
      'https://development-rememberme.artofinfo.eu/api/password-change',
      body,
      { headers }
    );
  }

  logout() {
    return this.auth
      .signOut()
      .then(() => {})
      .catch((error: any) => {
        this.setAuthErrorMessage(error.message);
      });
  }

  private setAuthErrorMessage(errorMessage: string): void {
    this.authErrorMessage.next(errorMessage);
  }
}
