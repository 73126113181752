import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NavigationPaths, CreateDoctorConnectionModel, CreateDoctorAppointmentModel, ISentAppointmentData } from 'src/app/models/enum';
import { DataService } from 'src/app/services/data.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Location } from '@angular/common';
import { IConnectionsModel } from 'src/app/models/connection';
@Component({
  selector: 'app-create-doctor-appointment',
  templateUrl: './create-doctor-appointment.component.html',
  styleUrls: ['./create-doctor-appointment.component.scss']
})
export class CreateDoctorAppointmentComponent implements OnInit {
  private signalOnDestroy$: Subject<boolean> = new Subject();
  navigationPaths = NavigationPaths;
  response: any;
  isSubmitted: boolean = false;
  connections: Array<IConnectionsModel> | undefined;
  acceptedConnections: Array<IConnectionsModel> | undefined;
  createDoctorAppointmentForm: FormGroup = new FormGroup({
    otherPartyExternalUserId: new FormControl('', Validators.required),
    interactionDateTime: new FormControl('', Validators.required),
    interactionMode: new FormControl('', Validators.required),
    reason: new FormControl('', Validators.required)
  });
  constructor(
    private router: Router,
    private dataService: DataService,
    private dialog: MatDialog,
    private location: Location
  ) { }

  ngOnInit(): void {
    this.dataService.getConnections().subscribe((res: any) => { // get list of connections
      this.connections = res;
      this.acceptedConnections = this.connections?.filter((element: IConnectionsModel) => {
        return element.connectionStatus === 'Accepted';
      });
    });

  }

  ngOnDestroy() {
    this.signalOnDestroy$.next(true);
    this.signalOnDestroy$.complete();
  }

  createAppointment() {
    this.isSubmitted = true;

    let createAppointment: ISentAppointmentData = {
      otherPartyExternalUserId: this.createDoctorAppointmentForm.get("otherPartyExternalUserId")!.value,
      interactionDateTime: this.createDoctorAppointmentForm.get("interactionDateTime")!.value,
      interactionMode: this.createDoctorAppointmentForm.get("interactionMode")!.value,
      reason: this.createDoctorAppointmentForm.get("reason")!.value,
      message: '',
      isError: false
    }

    this.dataService.createAppointmentAPI(createAppointment).pipe(takeUntil(this.signalOnDestroy$)).subscribe((err: any) => {
      if (!(err instanceof HttpErrorResponse)) {
        this.dialog.open(DialogComponent, {
          data: {
            description: "Your appointment was sent successfully!",
            duration: 2000
          }
        });
        this.router.navigateByUrl(this.navigationPaths.DoctorAppointmentList);
      }
    });

  }
  goBack(): void {
    this.location.back();
  }
  cancel() {
    this.router.navigateByUrl(this.navigationPaths.DoctorHomepageComponent);
  }

}
