<div *ngIf="content == undefined; else elseBlock">
  <mat-progress-spinner
    class="progress-spinner"
    color="blue"
    mode="indeterminate"
  >
  </mat-progress-spinner>
</div>
<ng-template #elseBlock>
  <mat-dialog-content>
    <div [innerHtml]="content | safeHtml"></div>
  </mat-dialog-content>
</ng-template>
