<div class="content">
  <form
    class="form"
    [formGroup]="changePasswordForm"
    (ngSubmit)="changePassword()"
  >
    <div class="title">
      <h1>CHANGE YOUR PASSWORD</h1>
    </div>
    <div class="subtitle">
      <div class="subtitle-text">Enter your new password below</div>
    </div>
    <div class="field">
      <label>Password</label>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Type...</mat-label>
      <input
        type="password"
        formControlName="password"
        placeholder="New password"
        matInput
      />
      <mat-error *ngIf="this.changePasswordForm.invalid"
        >Please type your password!
      </mat-error>
    </mat-form-field>
    <div class="field">
      <label>Confirm password</label>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Type...</mat-label>
      <input
        type="password"
        formControlName="confirmPassword"
        placeholder="Confirm password"
        matInput
      />
      <mat-error *ngIf="this.changePasswordForm.invalid"
        >Please type your password!
      </mat-error>
    </mat-form-field>
    <button type="submit" class="submitBtn">CHANGE PASSWORD</button>
    <div class="back" (click)="goTo(navigationPaths.UserProfile)">
      <div class="text">BACK TO USER PROFILE</div>
    </div>
  </form>
</div>
