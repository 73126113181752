import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationPaths, RecommendationModel } from 'src/app/models/enum';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-create-recommendation',
  templateUrl: './create-recommendation.component.html',
  styleUrls: ['./create-recommendation.component.scss']
})
export class CreateRecommendationComponent implements OnInit {
  postId: any;
  navigationPaths = NavigationPaths;
  recommendationForm: FormGroup = new FormGroup({
    startDate: new FormControl('', Validators.required),
    endDate: new FormControl('', Validators.required),
    recurringEndDate: new FormControl('', Validators.required),
    inferred: new FormControl('', Validators.required),
    recurring: new FormControl('', Validators.required),
    message: new FormControl('', Validators.required),
    type: new FormControl('', Validators.required),
    recurringPeriod: new FormControl('', Validators.required),
    remembermeId: new FormControl('', Validators.required),
    recommendationMessageId: new FormControl('', Validators.required)
  });
  constructor(
    private router: Router,
    private dataService: DataService
  ) { }


  ngOnInit(): void {
  }

  createEmployee() {
    if (this.recommendationForm.valid) {
      let recommendation: RecommendationModel = {
        startDate: this.recommendationForm.get("startDate")!.value,
        endDate: this.recommendationForm.get("endDate")!.value,
        recurringEndDate: this.recommendationForm.get("recurringEndDate")!.value,
        inferred: this.recommendationForm.get("inferred")!.value,
        recurring: this.recommendationForm.get("recurring")!.value,
        message: this.recommendationForm.get("message")!.value,
        type: this.recommendationForm.get("type")!.value,
        recurringPeriod: this.recommendationForm.get("recurringPeriod")!.value,
        remembermeId: this.recommendationForm.get("remembermeId")!.value,
        recommendationMessageId: this.recommendationForm.get("recommendationMessageId")!.value
      }

      this.dataService.createRecommendationAPI(recommendation)
      this.router.navigateByUrl(this.navigationPaths.Recommendations);
    }
  }

  cancel() {
    this.router.navigateByUrl(this.navigationPaths.Recommendations);
  }
}

