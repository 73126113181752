<div class="content">
  <img (click)="goBack()" src="assets/images/arrow-left-circle-fill.svg" />
  <div class="title">
    <h1>SCHEDULED APPOINTMENTS</h1>
  </div>
  <div class="no-data-text" *ngIf="newArrayAppointments.length == 0">
    <h3>There are no appointments</h3>
  </div>
  <div *ngFor="let appointment of newArrayAppointments; let index = index">
    <h3>Appointments for {{ checkDate(appointment.date) }}</h3>
    <div *ngFor="let element of appointment.content; let index = index">
      <div class="card-column">
        <div class="card">
          <div class="card-left">
            <img class="img" src="assets/images/cardAppointmentLogo.svg" />
            <span class="name">{{ element.info.primaryUser.fullName }}</span>
          </div>
          <div class="card-middle">
            <p>{{ element.hour }}</p>
          </div>
          <div class="card-right">
            <p>DETAILS</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
