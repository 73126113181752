<div class="login-form">
  <div class="logo">
    <img src="assets/images/Logo-transparent.png" />
  </div>
  <div class="text-info">
    <div class="title">
      WELCOME TO THE REMEMBER-ME PORTAL FOR HEALTHCARE PROFESSIONALS
    </div>
    <div class="sub-title">
      The ReMember-Me portal is a web application allowing healthcare
      professionals to connect with their clients, view their performance in the
      ReMember-Me system and interact with them by booking appointments and
      reviewing their assessments and training plan.
    </div>
  </div>
  <div class="container">
    <div class="title">
      <div class="text">Sign in as Healthcare Professional</div>
    </div>
    <form [formGroup]="signinForm" (ngSubmit)="loginUser()">
      <hr />
      <div class="field">
        <label>Username</label>
       
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Type...</mat-label>
        <input formControlName="loginName" type="text" matInput />
        <mat-error *ngIf="this.signinForm.invalid || this.submitted"
          >Please type your Username!
        </mat-error>
      </mat-form-field>
      <div class="field">
        <label>Password</label>
       
      </div>
      <mat-form-field appearance="outline">
        <mat-label>Type...</mat-label>
        <input formControlName="password" type="password" matInput />
        <mat-error *ngIf="this.signinForm.invalid || this.submitted"
          >Please type your Password!
        </mat-error>
      </mat-form-field>
      <div class="flex-content">
        <div class="form-group">
          <div *ngFor="let value of privacy; let i = index">
            <input
              id="privacy"
              type="checkbox"
              formArrayName="selectedPrivacyPolicy"
              [value]="value.value"
              (change)="onCheckboxChange($event)"
            />
            <label for="privacy"> {{ value.name }} </label>
          </div>
          <div
            *ngIf="submitted && f.selectedPrivacyPolicy.errors"
            class="privacyPolicyAlert"
          >
            <div *ngIf="f.selectedPrivacyPolicy.errors?.required">
              Privacy Policy and T&C is required!
            </div>
          </div>
          <p class="policy" (click)="openDialog()">
            Click here to see Privacy Policy and T&C
          </p>
        </div>
        <div
          class="forgot-password"
          (click)="goTo(navigationPaths.ForgotPassword)"
        >
          <div class="text">Forgot Password ?</div>
        </div>
      </div>
      <button type="submit" class="loginBtn">Login</button>
      <div class="container register">
        <p (click)="this.router.navigate(['/register'])">
          Don't have an account? <strong>Register here</strong>.
        </p>
      </div>
    </form>
  </div>
</div>
