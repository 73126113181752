import { array } from '@amcharts/amcharts5';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NavigationPaths } from 'src/app/models/enum';
import { IProfile, ISendProfile } from '../../models/user';
import { AuthService } from '../../services/auth.service';
import { DataService } from '../../services/data.service';

@Component({
    selector: 'app-user-profile',
    templateUrl: './user-profile.component.html',
    styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
    profileData: IProfile | undefined;
    navigationPaths = NavigationPaths;
    isModify: boolean = false;
    isSubmitted: boolean = false;
    profileForm = new FormGroup({
        fullName: new FormControl('', Validators.required),
        country: new FormControl('', Validators.required),
        city: new FormControl('', Validators.required),
       
    });
    constructor(
        private router: Router,
        public fb: FormBuilder,
        private authService: AuthService,
        private dataService: DataService
    ) { }

    ngOnInit(): void {
        this.getUserData();
    }

    updateProfileData() {

        if (this.profileForm.valid) {
            const profileForm: ISendProfile = {
                fullName: this.profileForm.value.fullName,
                country: this.profileForm.value.country,
                city: this.profileForm.value.city,
            }
            this.isModify = false;
            this.dataService.updateProfile(profileForm)
        }
    }

    onModify() {
        this.isModify = !this.isModify
    }
    getUserData() {
        this.isSubmitted = true;
        this.dataService.userData().subscribe((res: IProfile | any) => {
            this.profileData = res;
            this.profileForm.setValue({
                fullName: this.profileData!.fullName,
                country: this.profileData!.country,
                city: this.profileData!.city,
            });
        });
    }

    goTo(url: string) {
        this.router.navigateByUrl(url);
    }
}
