import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { ISentConnectionData, NavigationPaths } from 'src/app/models/enum';
import { DataService } from 'src/app/services/data.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { Location } from '@angular/common';


@Component({
  selector: 'app-create-doctor-connection',
  templateUrl: './create-doctor-connection.component.html',
  styleUrls: ['./create-doctor-connection.component.scss']
})
export class CreateDoctorConnectionComponent implements OnInit, OnDestroy {
  private signalOnDestroy$: Subject<boolean> = new Subject();

  navigationPaths = NavigationPaths;
  response: any;
  isSubmitted: boolean = false;
  createDoctorConnectionForm: FormGroup = new FormGroup({
    emailAddress: new FormControl('', Validators.email),
    phoneNumber: new FormControl('', Validators.required),
    accessLevel: new FormControl('HealthcareProfessional', Validators.required),
    customMessage: new FormControl('', Validators.required),
    nickName: new FormControl(''),
    relation: new FormControl('')
  });
  constructor(
    private router: Router,
    private dataService: DataService,
    private dialog: MatDialog,
    private location: Location

  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.signalOnDestroy$.next(true);
    this.signalOnDestroy$.complete();
  }

  createConnection() {
    this.isSubmitted = true;

    let createConnection: ISentConnectionData = {
      emailAddress: this.createDoctorConnectionForm.get("emailAddress")!.value,
      phoneNumber: this.createDoctorConnectionForm.get("phoneNumber")!.value,
      accessLevel: this.createDoctorConnectionForm.get("accessLevel")!.value,
      customMessage: this.createDoctorConnectionForm.get("customMessage")!.value,
      nickName: this.createDoctorConnectionForm.get("nickName")!.value,
      relation: this.createDoctorConnectionForm.get("relation")!.value,
      message: '',
      isError: false
    }
    this.dataService.createConnectionAPI(createConnection).pipe(takeUntil(this.signalOnDestroy$)).subscribe((err: any) => {
      if (!(err instanceof HttpErrorResponse)) {
        this.dialog.open(DialogComponent, {
          data: {
            description: "Your connection was sent successfully!",
            duration: 2000
          }
        });
        this.router.navigateByUrl(this.navigationPaths.DoctorConnectionList);
      }
    });
  }

  goBack(): void {
    this.location.back();
  }
  
  cancel() {
    this.router.navigateByUrl(this.navigationPaths.DoctorHomepageComponent);
  }

}
