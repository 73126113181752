import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PrivacyPolicyComponent } from 'src/app/components/privacy-policy/privacy-policy.component';
import { NavigationPaths } from 'src/app/models/enum';
import { AuthService } from 'src/app/services/auth.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  privacy: Array<any> = [
    { name: 'Accept Privacy Policy and T&C', value: 'privacyPolicy' },
  ];
  navigationPaths = NavigationPaths;
  signinForm = new FormGroup({
    loginName: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    selectedPrivacyPolicy: new FormArray([], Validators.required)
  });
  submitted = false;
  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router,
    public dialog: MatDialog

  ) {
  }
  ngOnInit() { }

  loginUser() {
    this.submitted = true;
    if (this.signinForm.invalid) {
      return;
    }
    this.authService.signIn(this.signinForm.value);
  }

  openDialog() {
    this.dialog.open(PrivacyPolicyComponent);
  }

  onCheckboxChange(event: any) {
    const selectedPrivacyPolicy = (this.signinForm.controls.selectedPrivacyPolicy as FormArray);
    if (event.target.checked) {
      selectedPrivacyPolicy.push(new FormControl(event.target.value));
    } else {
      const index = selectedPrivacyPolicy.controls
        .findIndex(x => x.value === event.target.value);
      selectedPrivacyPolicy.removeAt(index);
    }
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }
  
  get f() { return this.signinForm.controls }

}
