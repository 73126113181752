<div class="config">
    <div class="content-filtering">
        <h1>Test results for {{this.testName}}</h1>
        <div class="content-top">
            <img (click)="goBack()" src="assets/images/arrow-left-circle-fill.svg" />
            <h2>CLIENT ID: {{this.dataService.sharedNickName}}</h2>
        </div>
        <h2>See the test results of the period</h2>
        <div class="btn btn-success" (click)="getDataByRemembeId()"> See the current year's results </div>
    </div>
</div>
<div class="cont" *ngFor="let data of dataCollected; let i = index">
    <div class="title">
        <b>DATE: </b>{{data.dateTime}} <br>
        <a href="#List-{{ i }}" data-toggle="collapse" class="btn btn-default btn-xs">
            <span class="glyphicon glyphicon-eye-open"></span> See more items
        </a>
    </div>
    <div id="List-{{ i }}" class="collapse">
        <ul style="list-style: none">
            <div *ngFor="let name of data.assessmentResultItems; let index = index">
                <div class="exercise-number">Exercise Number: {{ name.numberOfExercise }}</div>
                <table class="table table-striped">
                    <thead>
                        <tr>
                            <th>Question</th>
                            <th>Score</th>
                            <th>Answer</th>
                            <th>Correct Answer</th>
                            <th>Time Spent</th>
                        </tr>
                    </thead>
                    <tr>
                        <td>{{ name.question }}</td>
                        <td>{{ name.score }}</td>
                        <td>
                            <ng-container *ngIf="name.answer">
                                <ng-container
                                    *ngIf="name.numberOfExercise === 2 || name.numberOfExercise === 3 || name.numberOfExercise === 15">
                                    <img [src]="transform(name.answer)" alt="answer" />
                                </ng-container>
                                <ng-container
                                    *ngIf="name.numberOfExercise !== 2 && name.numberOfExercise !== 3 && name.numberOfExercise !== 15">
                                    <span>{{ name.answer }}</span>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>
                            <ng-container *ngIf="name.correctAnswer">
                                <ng-container
                                    *ngIf="name.numberOfExercise === 2 || name.numberOfExercise === 3 || name.numberOfExercise === 15">
                                    <img [src]="transform(name.correctAnswer)" alt="correctAnswer" />
                                </ng-container>
                                <ng-container
                                    *ngIf="name.numberOfExercise !== 2 && name.numberOfExercise !== 3 && name.numberOfExercise !== 15">
                                    <span>{{ name.correctAnswer }}</span>
                                </ng-container>
                            </ng-container>
                        </td>
                        <td>{{ name.timeSpent }}</td>
                    </tr>
                    <tr *ngIf="name.resultType === 'MoreInOne'">
                        <td class="mini-table">
                            <div *ngFor="let subTask of name.subTaskResults">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th>Question</th>
                                            <th>Score</th>
                                            <th>Answer</th>
                                            <th>Correct Answer</th>
                                            <th>Time Spent</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td>{{ subTask.question }}</td>
                                        <td>{{ subTask.score }}</td>
                                        <td>
                                            <ng-container *ngIf="subTask.answer">
                                                <ng-container *ngIf="subTask.numberOfExercise === 8">
                                                    <img [src]="transform(subTask.answer)" alt="answer" />
                                                </ng-container>
                                                <ng-container *ngIf="subTask.numberOfExercise !== 8">
                                                    <span>{{ subTask.answer }}</span>
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>
                                            <ng-container *ngIf="subTask.correctAnswer">
                                                <ng-container *ngIf="subTask.numberOfExercise === 8">
                                                    <img [src]="transform(subTask.correctAnswer)" alt="correctAnswer" />
                                                </ng-container>
                                                <ng-container *ngIf="subTask.numberOfExercise !== 8">
                                                    <span>{{ subTask.correctAnswer }}</span>
                                                </ng-container>
                                            </ng-container>
                                        </td>
                                        <td>{{ subTask.timeSpent }}</td>
                                    </tr>
                                </table>
                            </div>
                        </td>
                    </tr>
                </table>
            </div>
        </ul>

    </div>
    <br> <br> <br>
</div>