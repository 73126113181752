<div class="content-container">
    <form [formGroup]="recommendationForm" class="recommendation-form">
        <div class="form-label">Enter a date range</div>
        <mat-form-field appearance="fill">
            <mat-label>Select date</mat-label>
            <mat-date-range-input [rangePicker]="picker">
                <input matStartDate formControlName="startDate" placeholder="Start date">
                <input matEndDate formControlName="endDate" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
        <div class="form-label">Enter recurring end date</div>
        <mat-form-field appearance="fill">
            <mat-label>Select date</mat-label>
            <input matInput formControlName="recurringEndDate" [matDatepicker]="picker1">
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>
        <div class="form-label">Inferred</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="inferred" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Recurring</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="recurring" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Message</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="message" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Type</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="type" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Recurring Period</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="recurringPeriod" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Rememberme Id</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="remembermeId" type="text" matInput>
        </mat-form-field>
        <div class="form-label">Recommendation Message Id</div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="recommendationMessageId" type="text" matInput>
        </mat-form-field>
        <div class="actions">
            <button mat-button class="action-button cancel" (click)="cancel()">
                Cancel
            </button>
            <button type="button" mat-button class="action-button add" (click)="createEmployee()">
                Save
            </button>
        </div>
    </form>
</div>