import { Component, OnInit } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { Router } from '@angular/router';
import { NavigationPaths } from 'src/app/models/enum';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-doctor-api-connection',
  templateUrl: './doctor-api-connection.component.html',
  styleUrls: ['./doctor-api-connection.component.scss']
})
export class DoctorApiConnectionComponent implements OnInit {
  data: any = [];
  latestScore: Array<any> = [];
  latestScoreStatic: Array<any> = [];
  dataConnection: any = [];
  externalUserID: string | undefined;
  navigationPaths = NavigationPaths;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  constructor(
    private router: Router,
    public authService: AuthService,
    public dataService: DataService,
    private location: Location
  ) {
  }
  ngOnInit() {
    this.dataService.getConnections().subscribe((res) => { // get list of connections
      this.data = res;
    });
  }

  getCurrentUIDwasClicked(externalUserID: string, connectionNickName: string) {
    this.dataService.sharedUidValue = externalUserID; // save the uid -> when you clicked on a chart, data provided based on uid for each client of a connection list
    this.dataService.sharedNickName = connectionNickName;
    this.goTo('my-client-details');
  }

  goBack(): void {
    this.location.back();
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  hasPending(): boolean {
    return this.data.some((item: { connectionStatus: string; }) => item.connectionStatus === 'Pending');
  }

  hasConnection(): boolean {
    return this.data.some((item: { connectionStatus: string; }) => item.connectionStatus === 'Accepted');
  }

  hasRejected(): boolean {
    return this.data.some((item: { connectionStatus: string; }) => item.connectionStatus === 'Reject');
  }

}
