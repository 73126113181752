<h1>Through this interface, actors or other modules may retrieve a recommendation for a certain user in a selected
    timeframe:</h1>
    <div class="config">
        <mat-form-field appearance="fill">
            <mat-label>Enter a date range</mat-label>
            <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="Start date">
                <input matEndDate formControlName="end" placeholder="End date">
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>

            <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
            <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Enter RememberMe ID</mat-label>
            <input matInput [(ngModel)]="remembermeId">
          </mat-form-field>

        <div class="btn btn-success" (click)="getDataByRemembeId()">Filter</div>
    </div>
    <div class="cont" *ngFor="let data of dataJsn; let i = index">

        <ul style="list-style: none">

            <table>
                <thead>
                    <tr>
                        <th>Publish Date</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Inferred</th>
                        <th>Recurring</th>
                        <th>Message</th>
                        <th>Type</th>
                        <th>Recurring Period</th>
                        <th>Recurring End Date</th>
                        <th>Rememberme Id</th>
                        <th>Recommendation Message Id</th>
                    </tr>
                </thead>
                <tr>
                    <td>{{data.publishDate}}</td>
                    <td>{{data.startDate}}</td>
                    <td> {{data.endDate}}</td>
                    <td> {{data.inferred}}</td>
                    <td>{{data.recurring}}</td>
                    <td>{{data.message}} </td>
                    <td>{{data.type}}</td>
                    <td>{{data.recurringPeriod}} </td>
                    <td>{{data.recurringEndDate}} </td>
                    <td>{{data.remembermeId}}</td>
                    <td>{{data.recommendationMessageId}} </td>
                </tr>
            </table>

        </ul>

        <br> <br> <br>
    </div>
