<div class="content-container">
  <img (click)="goBack()" src="assets/images/arrow-left-circle-fill.svg" />
  <h1>CREATE APPOINTMENT</h1>
  <form [formGroup]="createDoctorAppointmentForm" class="createDoctorAppointmentForm">
    <div class="field">
      <label>Select an option</label>
      <p class="info">
        <span>In this list are the names of accepted connections</span>
        <mat-icon>info</mat-icon>
      </p>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Select an option...</mat-label>
      <mat-select formControlName="otherPartyExternalUserId">
        <mat-option *ngFor="let connection of acceptedConnections" [value]="connection.externalUserId">
          {{ connection.connectionName }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="createDoctorAppointmentForm.get('otherPartyExternalUserId')?.invalid && isSubmitted">
        Please select a Type!
      </mat-error>
    </mat-form-field>
    <div class="field">
      <label>Interaction date time</label>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Type...</mat-label>
      <input formControlName="interactionDateTime" type="datetime-local" matInput />
      <mat-error *ngIf="this.createDoctorAppointmentForm.invalid || this.isSubmitted">Please type your Interaction date
        time!
      </mat-error>
    </mat-form-field>
    <div class="field">
      <label>Interaction mode</label>
      <p class="info">
        <span>Example: call, video, game</span>
        <mat-icon>info</mat-icon>
      </p>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Type...</mat-label>
      <input formControlName="interactionMode" type="text" matInput />
      <mat-error *ngIf="this.createDoctorAppointmentForm.invalid || this.isSubmitted">Please type your Interaction mode!
      </mat-error>
    </mat-form-field>
    <div class="field">
      <label>Reason</label>
    </div>
    <mat-form-field appearance="outline">
      <mat-label>Type...</mat-label>
      <input formControlName="reason" type="text" matInput />
      <mat-error *ngIf="this.createDoctorAppointmentForm.invalid || this.isSubmitted">Please type your Reason!
      </mat-error>
    </mat-form-field>
    <div class="actions">
      <button mat-button class="action-button cancel" (click)="cancel()">
        <p>CANCEL</p>
      </button>
      <button mat-button class="action-button add" type="button" (click)="createAppointment()">
        <p>SAVE</p>
      </button>
    </div>
  </form>
</div>