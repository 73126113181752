import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ThemePalette } from '@angular/material/core';
import { MatDialog } from '@angular/material/dialog';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';
import { Router } from '@angular/router';
import { NavigationPaths } from 'src/app/models/enum';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { DialogComponent } from 'src/app/shared/dialog/dialog.component';
import { map } from 'rxjs/operators';
import { forkJoin } from 'rxjs';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-client-details',
  templateUrl: './my-client-details.component.html',
  styleUrls: ['./my-client-details.component.scss']
})
export class MyClientDetailsComponent implements OnInit {
  data: any = [];
  latestScore: Array<any> = [];
  latestScoreStatic: Array<any> = [];
  dataConnection: any = [];
  externalUserID: string | undefined;
  navigationPaths = NavigationPaths;
  color: ThemePalette = 'primary';
  mode: ProgressSpinnerMode = 'determinate';
  dataRangeScores: any = [];
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });

  constructor(
    private router: Router,
    public authService: AuthService,
    public dataService: DataService,
    public datepipe: DatePipe,
    public dialog: MatDialog,
    private location: Location
  ) {
  }
  ngOnInit() {
    this.dataService.getConnections().subscribe((res) => { // get list of connections
      this.data = res;
    });
    this.getCurrentUIDwasClicked();
  }

  details(assesmentType: string) {
    this.dataService.assesmentType = assesmentType;
    this.goTo('assessment');
  }

  goBack(): void {
    this.location.back();
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }
  getCurrentUIDwasClicked() {
    this.externalUserID = this.dataService.sharedUidValue; // "23fe665e-3ee8-2c01-303b-ee319b943964"; // save the uid -> when you clicked on a chart, data provided based on uid for each client of a connection list
    const rmcas$ = this.dataService.getLatestScore(this.externalUserID, "RMCAS").pipe(
      map((data: any) => ({ RMCAS: Math.floor((data / 61) * 100) }))
    );
    const gds$ = this.dataService.getLatestScore(this.externalUserID, "GDS").pipe(
      map((data: any) => ({ GDS: Math.floor((data / 15) * 100) }))
    );
    const pws$ = this.dataService.getLatestScore(this.externalUserID, "Personal Wellbeing").pipe(
      map((data: any) => ({ PWS: Math.floor((data / 12) * 100) }))
    );
    const sleep$ = this.dataService.getLatestScore(this.externalUserID, "Sleep quality scale").pipe(
      map((data: any) => ({ Sleep: Math.floor((data / 10) * 100) }))
    );
    const djg$ = this.dataService.getLatestScore(this.externalUserID, "De Jong Gierveld Loneliness").pipe(
      map((data: any) => ({ DJG: Math.floor((data / 11) * 100) }))
    );
    const rapa$ = this.dataService.getLatestScore(this.externalUserID, "RAPA").pipe(
      map((data: any) => ({ RAPA: Math.floor((data / 10) * 100) }))
    );
    forkJoin([rmcas$, gds$, pws$, sleep$, djg$, rapa$]).subscribe((results: any[]) => {
      this.latestScore = results;
    });
  }

  listWithAllScores() {
    this.externalUserID = this.dataService.sharedUidValue; // save the uid -> when you clicked on a chart, data provided based on uid for each client of a connection list
    let startDate ='2023-01-01';
    const currentDate = new Date(); // Get the current date
    let endDate = this.datepipe.transform(currentDate, 'yyyy-MM-dd');
    this.dataService.getAllTheScoresRegistered(this.externalUserID, startDate, endDate).pipe().subscribe(data => {
      this.dataRangeScores = data;
      if (this.dataRangeScores == null || this.dataRangeScores.length == 0 ) {
        this.dialog.open(DialogComponent, {
          data: {
            description: "No data was found in this interval",
            duration: 3000
          }
        });
      }
    });
  }
}