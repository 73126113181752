import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { NavigationPaths } from "src/app/models/enum";
import { AuthService } from "src/app/services/auth.service";
import { DataService } from "src/app/services/data.service";



@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public dataJsn: any = [];
  public show: boolean = false;
  public buttonName: any = 'show';
  navigationPaths = NavigationPaths;
  constructor(
    public dataService: DataService,
    private authService: AuthService,
    public router: Router,
  ) { }


  showDiv1 = {
    next: false
  }


  ngOnInit(): void {
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  logout() {
    this.authService.doLogout();
  }
}
