<div class="register-form">
    <div class="logo">
        <img src="assets/images/Logo-transparent.png">
    </div>
    <div class="container">
        <form [formGroup]="signupForm" (ngSubmit)="registerUser()">
            <div class="header">
                <h1 class="title">Register</h1>
                <p class="subtitle">Please fill in this form to create an account.</p>
                <hr>
            </div>
            <div class="field">
                <label>Email</label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="email" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    Email! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Phone Number</label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="phoneNumber" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    Phone Number! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>User Name</label>
                <p class="info">
                    <span>With this you will log in</span>
                    <mat-icon>info</mat-icon>
                </p>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="userName" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    user name! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Full Name</label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="fullName" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    full name! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Country</label>
            </div>

            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="country" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    country! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>City</label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="city" type="text" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    city! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Password</label>               
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="password" type="password" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    Password! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Password repeat</label>
            </div>
            <mat-form-field appearance="outline">
                <mat-label>Type...</mat-label>
                <input formControlName="passwordRepeat" type="password" matInput>
                <mat-error *ngIf="this.signupForm.invalid || this.isSubmitted">Please type your
                    Password! </mat-error>
            </mat-form-field>
            <div class="field">
                <label>Gender </label>
            </div>
            <mat-form-field appearance="outline">
                <mat-select formControlName="gender" placeholder="Click here ">
                    <mat-option value="Male">
                        Male
                    </mat-option>
                    <mat-option value="Female">
                        Female
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <hr>
            <button type="submit" class="registerbtn">Register</button>
            <div class="container login">
                <p (click)="this.router.navigate(['/login']);">Already have an account? <strong>Login here</strong>.</p>
            </div>
        </form>
    </div>

</div>