<div class="main-container">
    <div class="content-top">
        <img (click)="goBack()" src="assets/images/arrow-left-circle-fill.svg" />
        <h2>CLIENT ID: {{this.dataService.sharedNickName}}</h2>
    </div>
    <div class="cards-orientation">
        <div class="cards" *ngFor="let score of latestScore">
            <mat-card class="card" *ngIf="score.RMCAS || score.RMCAS === 0" (click)="details('RMCAS')">
                <mat-card-content class="card-content">
                    <h2 class="h2">COGNITION</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.RMCAS }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.RMCAS }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.RMCAS === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">COGNITION</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.GDS || score.GDS === 0" (click)="details('GDS')">
                <mat-card-content class="card-content">
                    <h2 class="h2">MOOD</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.GDS }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.GDS }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.GDS === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">MOOD</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.PWS || score.PWS === 0" (click)="details('Personal Wellbeing')">
                <mat-card-content class="card-content">
                    <h2 class="h2">WELLBEING</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.PWS }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.PWS }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.PWS === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">WELLBEING</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.Sleep || score.Sleep === 0" (click)="details('Sleep quality scale')">
                <mat-card-content class="card-content">
                    <h2 class="h2">SLEEP</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.Sleep }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.Sleep }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.Sleep === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">SLEEP</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.DJG || score.DJG === 0" (click)="details('De Jong Gierveld Loneliness')">
                <mat-card-content class="card-content">
                    <h2 class="h2">SOCIALIZATION</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.DJG }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.DJG }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.DJG === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">SOCIALIZATION</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.RAPA || score.RAPA === 0" (click)="details('RAPA')">
                <mat-card-content class="card-content">
                    <h2 class="h2">RAPA</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="{{ score.RAPA }}">
                    </mat-progress-spinner>
                    <div class="text">{{ score.RAPA }}%</div>
                </mat-card-content>
            </mat-card>
            <mat-card class="card" *ngIf="score.RAPA === null">
                <mat-card-content class="card-content">
                    <h2 class="h2">RAPA</h2>
                    <mat-progress-spinner class="spinner" color="primary" mode="determinate" value="0">
                    </mat-progress-spinner>
                    <h2>No data found yet</h2>
                </mat-card-content>
            </mat-card>
            
        </div>
    </div>

    <div class="all-scores-registered">
        <h2>
            Select a date range to view data for Sleep, Physical and Cognitive:
        </h2>
        <div class="range">
            <div class="btn btn-success" (click)="listWithAllScores()"> See the current year's results </div>
        </div>
        <table *ngIf="this.dataRangeScores !== null && this.dataRangeScores.length > 0">
            <thead>
                <tr>
                    <th>Type</th>
                    <th>Rating</th>
                    <th>Score</th>
                    <th>Date Time</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of dataRangeScores">
                    <td>{{ item.name }}</td>
                    <td>{{ item.rating }}</td>
                    <td>{{ item.score }}</td>
                    <td>{{ item.dateTime }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>