import { DatePipe } from "@angular/common";
import { Component, OnInit } from "@angular/core";
import { FormGroup, FormControl } from "@angular/forms";
import { take } from "rxjs/operators";
import { DataService } from "src/app/services/data.service";
// amCharts imports
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
@Component({
  selector: 'app-daily-activities',
  templateUrl: './daily-activities.component.html',
  styleUrls: ['./daily-activities.component.css']
})
export class DailyActivitiesComponent implements OnInit {
  public measurementActivityType: string = "";
  public dataMeasurement: any;
  public allActivityType: any;
  public activityTypeSelected: any;
  public remembermeId: any;
  public dataFitbit: Array<any> = [];
  public range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  public allDates: Array<string> = []

  constructor(
    public dataService: DataService,
    public datepipe: DatePipe) { }

  ngOnInit(): void {
  }

  showChart() {

    var root = am5.Root.new("chartdiv");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panY",
      wheelY: "zoomY",
      layout: root.verticalLayout
    }));
    chart.set("scrollbarY", am5.Scrollbar.new(root, {
      orientation: "vertical"
    }));


    let z = {}

    var data = this.dataFitbit.map(item => {
      item.measurements.forEach((element: any) => {
        z = {
          ...z,
          [element.name]: element.value
        }
      });

      return {
        name: item.name,
        dateTime: item.dateTime,
        score: item.score,
        ...z
      }
    });

    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "dateTime",
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererX.new(root, {})
    }));

    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));

    function makeSeries(name: any, fieldName: any) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: yAxis,
        valueXField: fieldName,
        categoryYField: "dateTime"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryY}: {valueX}",
        tooltipY: am5.percent(90)
      });
      series.data.setAll(data);
      series.appear();
      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueX}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });
      legend.data.push(series);
    }
    makeSeries("Deep Stage", "deepStage");
    makeSeries("Light Stage", "lightStage");
    makeSeries("Rem stage", "remStage");
    chart.appear(1000, 100);
    this.showSecondaryChart();
  }

  showSecondaryChart() {

    var root = am5.Root.new("chartdivSecondary");
    root.setThemes([
      am5themes_Animated.new(root)
    ]);
    var chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "panY",
      wheelY: "zoomY",
      layout: root.verticalLayout
    }));
    chart.set("scrollbarY", am5.Scrollbar.new(root, {
      orientation: "vertical"
    }));
    let z = {}
    var data = this.dataFitbit.map(item => {
      item.measurements.forEach((element: any) => {
        z = {
          ...z,
          [element.name]: element.value
        }
      });
      return {
        dateTime: item.dateTime,
        ...z
      }
    });


    var yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
      categoryField: "dateTime",
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));

    yAxis.data.setAll(data);

    var xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
      min: 0,
      renderer: am5xy.AxisRendererX.new(root, {})
    }));



    var legend = chart.children.push(am5.Legend.new(root, {
      centerX: am5.p50,
      x: am5.p50
    }));


    function makeSeries(name: any, fieldName: any) {
      var series = chart.series.push(am5xy.ColumnSeries.new(root, {
        name: name,
        stacked: true,
        xAxis: xAxis,
        yAxis: yAxis,
        baseAxis: yAxis,
        valueXField: fieldName,
        categoryYField: "dateTime"
      }));

      series.columns.template.setAll({
        tooltipText: "{name}, {categoryY}: {valueX}",
        tooltipY: am5.percent(90)
      });
      series.data.setAll(data);

      series.appear();

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Label.new(root, {
            text: "{valueX}",
            fill: root.interfaceColors.get("alternativeText"),
            centerY: am5.p50,
            centerX: am5.p50,
            populateText: true
          })
        });
      });

      legend.data.push(series);
    }
    makeSeries("Total Minutes A Sleep", "totalMinutesAsleep");
    makeSeries("Total Time In Bed", "totalTimeInBed");
    chart.appear(1000, 100);

  }

  filterData() {
    this.dataService.getMeasurementTypes(this.measurementActivityType).pipe(take(1)).subscribe(data => {
      this.dataMeasurement = data;
    });
  }

  alert() {
    alert("Filter data successfully! Now press Show Chart");
  }

  getFitbitActivity() {
    let startDate = this.datepipe.transform(this.range.value.start, 'yyyy-MM-dd');
    let endDate = this.datepipe.transform(this.range.value.end, 'yyyy-MM-dd');
    this.dataFitbit = [];
    this.dataService.fitbitActivity(this.remembermeId, startDate, endDate).pipe(take(1)).subscribe((data: any) => {
      this.dataFitbit = data;
    });
  }

}
