import { Component, OnInit } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { MatDialog } from '@angular/material/dialog';
@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  isSubmitted: boolean = false;
  signupForm = new FormGroup({
    email: new FormControl('', Validators.required),
    phoneNumber: new FormControl('', Validators.required),
    userName: new FormControl('', Validators.required),
    fullName: new FormControl('', Validators.required),
    country: new FormControl('', Validators.required),
    city: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    passwordRepeat: new FormControl('', Validators.required),
    gender: new FormControl('', Validators.required),
    roleId: new FormControl(3, Validators.required),
  });
  constructor(
    public fb: FormBuilder,
    public authService: AuthService,
    public router: Router
  ) {}
  ngOnInit() {}

  registerUser() {
    this.isSubmitted = true;
    this.authService.signUp(this.signupForm.value);
  }
}
