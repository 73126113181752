<div class="content" *ngIf="data">
  <div class="content-top">
    <img (click)="goBack()"src="assets/images/arrow-left-circle-fill.svg" />
    <h1>CONNECTIONS</h1>
    <h3 (click)="goTo(navigationPaths.DoctorCreateConnection)">
      Add New Connections
    </h3>
  </div>
  <div *ngIf="externalUserID == undefined">
    <div>
      <ng-container *ngIf="hasPending(); else noPending">
        <h3>Pending Invitations</h3>
      </ng-container>
      <ng-template #noPending>
        <h3>There are no pending invitations</h3>
      </ng-template>
    </div>

    <div *ngFor="let element of data; let index = index">
      <div class="card-column" *ngIf="element.connectionStatus === 'Pending'">
        <div class="card light">
          <div class="card-left">
            <img class="left-image" src="assets/images/patianceImage.svg" />
            <span>{{ element.connectionUserName }}</span>
            <img class="right-image" src="assets/images/about.svg" />
          </div>
          <div class="card-middle">
            <p>{{ element.connectionRole }}</p>
          </div>
        
        </div>
      </div>
    </div>

    <div>
      <ng-container *ngIf="hasConnection(); else noConnection">
        <h3>Connections</h3>
      </ng-container>
      <ng-template #noConnection>
        <h3>There are no connections</h3>
      </ng-template>
    </div>

    <div *ngFor="let element of data; let index = index">
      <div class="card-column" *ngIf="element.connectionStatus === 'Accepted'">
        <div class="card light">
          <div class="card-left">
            <img class="left-image" src="assets/images/patianceImage.svg" />
            <span>{{ element.connectionUserName }}</span>
            <img class="right-image" src="assets/images/about.svg" />
          </div>
          <div class="card-middle">
            <p>{{ element.connectionRole }}</p>
          </div>
          <div (click)="getCurrentUIDwasClicked(element.externalUserId, element.connectionNickName)" class="card-right">
            <p>DETAILS</p>
          </div>
        </div>
      </div>
    </div>

    <div>
      <ng-container *ngIf="hasRejected(); else noRejected">
        <h3>Rejected</h3>
      </ng-container>
      <ng-template #noRejected>
        <h3>There are no rejected invitations</h3>
      </ng-template>
    </div>

    <div *ngFor="let element of data; let index = index">
      <div class="card-column" *ngIf="element.connectionStatus === 'Reject'">
        <div class="card light">
          <div class="card-left">
            <img class="left-image" src="assets/images/patianceImage.svg" />
            <span>{{ element.connectionUserName }}</span>
            <img class="right-image" src="assets/images/about.svg" />
          </div>
          <div class="card-middle">
            <p>{{ element.connectionRole }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>