import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { MatDialog } from '@angular/material/dialog';
import { DialogComponent } from '../shared/dialog/dialog.component';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private dialog: MatDialog) {}

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const authToken = this.authService.getToken();
    req = req.clone({
      setHeaders: {
        Authorization: 'Bearer ' + authToken,
      },
    });
    return next.handle(req).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.authService.doLogout();
        }
        if (
          error.status === 400 ||
          error.status === 401 ||
          error.status === 404 ||
          error.status === 409 ||
          error.status === 500
        ) {
          if (error.error.Message == undefined) {
            this.dialog.open(DialogComponent, {
              data: {
                description: error.error.title,
                duration: 4000,
              },
            });
          } else
            this.dialog.open(DialogComponent, {
              data: {
                description: error.error.Message,
                duration: 4000,
              },
            });
        }
        return throwError(error);
      })
    );
  }
}
