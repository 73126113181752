import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { take } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { AuthService } from '../../services/auth.service';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-recommandations',
  templateUrl: './recommandations.component.html',
  styleUrls: ['./recommandations.component.scss']
})
export class RecommandationsComponent implements OnInit {

  
  date = new FormControl(new Date());
  public base64Image: string = '';
  public dataJsn: any = [];
  public show: boolean = false;
  public buttonName: any = 'show';
  public base: any | undefined;
  public remembermeId: any;
  public assessmentType: string = "";
  public getTypesParam: number = 1;
  public getTypes: any;
  public getTypesAll: any;
  public measurementActivityType: string = "sleep";
  public fitbitId: string = "8XP5RH";
  public startTime: string = "2021-10-23";
  public endTime: string = "2021-10-23";
  public dataMeasurement: any;
  public dataFitbitId: any;

  public range = new FormGroup({
      start: new FormControl(),
      end: new FormControl(),
  });

  constructor(public dataService: DataService,
      private authService: AuthService,
      private sanitizer: DomSanitizer,
      public router: Router,
      public datepipe: DatePipe) { 
      }
       

  showDiv1 = {
      next: false
  }

  ngOnInit(): void {
     

  }

  getDataByRemembeId() {
      let startDate =this.datepipe.transform(this.range.value.start, 'yyyy-MM-dd');
      let endDate = this.datepipe.transform(this.range.value.end, 'yyyy-MM-dd');
      this.dataService.getRecommaendationDataByRemembeId(this.remembermeId,startDate, endDate).pipe(take(1)).subscribe(data => {
          this.dataJsn = data;
      });
  }

  transform(data: any) {
      this.base64Image = 'data:image/png;base64,' + data;
      return this.sanitizer.bypassSecurityTrustResourceUrl(this.base64Image);
  }

  logout() {
      this.authService.logout();
  }

  goTo(url: string) {
      this.router.navigateByUrl(url);
  }


}
