<div class="content-container">
    <img (click)="goBack()" src="assets/images/arrow-left-circle-fill.svg" />
    <h1 class="title">CREATE CONNECTION</h1>
    <form [formGroup]="createDoctorConnectionForm" class="createDoctorConnectionForm">
        <div class="field">
            <label>Email</label>
            <p class="info">
                <span>write the email for which you want to create a connection</span>
                <mat-icon>info</mat-icon>
            </p>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="emailAddress" type="text" matInput>
        </mat-form-field>
        <div class="field">
            <label>Phone Number *</label>
            <p class="info">
                <span>write the phone number for which you want to create a connection</span>
                <mat-icon>info</mat-icon>
            </p>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="phoneNumber" type="text" matInput >
            <mat-error *ngIf="this.createDoctorConnectionForm.invalid || this.isSubmitted">Please type your phone number! </mat-error>
        </mat-form-field>
        <div class="field">
            <label>Custom Message *</label>
            <p class="info">
                <span>If you have a specific message you can leave it here</span>
                <mat-icon>info</mat-icon>
            </p>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="customMessage" type="text" matInput >
            <mat-error *ngIf="this.createDoctorConnectionForm.invalid || this.isSubmitted"> Please type a custom message! </mat-error>
        </mat-form-field>
        <div class="field">
            <label>Nick Name</label>
            <p class="info">
                <span>Is used to personalize a connection. On the android app it's displayed on the connections list.</span>
                <mat-icon>info</mat-icon>
            </p>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="nickName" type="text" matInput>
        </mat-form-field>
        <div class="field">
            <label>Relation</label>
        </div>
        <mat-form-field appearance="outline">
            <mat-label>Type...</mat-label>
            <input formControlName="relation" type="text" matInput>
        </mat-form-field>
        <div class="actions">
            <button mat-button class="action-button cancel" (click)="cancel()">
                CANCEL
            </button>
            <button mat-button class="action-button add" type="submit" (click)="createConnection()">
                SAVE
            </button>
        </div>
    </form>
</div>