import { IResponse } from "./response";

export enum NavigationPaths {
    Root = "/",
    Home = "/home",
    Login = "/login",
    Register = "/register",
    ForgotPassword = "/forgot-password",
    DailyActivies = "/daily-activities",
    Assessments = "/assessment",
    Recommendations = "/recommendations",
    CreateRecommendation = "/create-recommendation",
    ChangePassword = "/change-password",
    DoctorConnectionList = "/doctor-connection",
    DoctorAppointmentList = "/doctor-appointment",
    DoctorCreateConnection = "/doctor-create-connection",
    DoctorCreateAppoiment = "/doctor-create-appointment",
    DoctorHomepageComponent = "/doctor-homepage",
    Notifications = "/",
    PrivacyPolicy = "/privacy-policy",
    UserProfile = "/profile",
}

export interface RegisterModel {

    email: string,
    phoneNumber: string,
    userName: string,
    fullName: string,
    country: string,
    city: string,
    password: string,
    passwordRepeat: string,
    gender: string,
    roleId: number
}

export interface LoginModel {
    loginName: string;
    password: string;
}

export interface RecommendationModel {
    startDate: Date,
    endDate: Date,
    recurringEndDate: Date,
    inferred: string,
    recurring: string,
    message: string,
    type: string,
    recurringPeriod: string,
    remembermeId: string,
    recommendationMessageId: string,
}

export interface CreateDoctorConnectionModel {

    emailAddress: string,
    phoneNumber: string,
    accessLevel: string,
    customMessage: string,
    nickName: string,
    relation: string
}

export interface CreateDoctorAppointmentModel {
    otherPartyExternalUserId: string,
    interactionDateTime: string,
    interactionMode: string,
    reason: string
}

export interface IReceiveDataCaregiver {
    email: string;
    phoneNumber: string;
    userName: string;
    fullName: string;
    gender: string;
    weight: number,
    height: number,
    birthDate: Date;
    country: string;
    city: string;
    assistanceHours: number,
    livingStatus: string;
    education: string;
    externalUserId: string;
    requiresAssistance: boolean,
    institute: string;
    introduction: string;
    rmcasFilled: boolean;
    motorFilled: boolean,
    personalWbFilled: boolean,
    gdsFilled: boolean,
    rapaFilled: boolean,
    djgFilled: boolean,
    sleepFilled: boolean,
    meta: string
}

export interface IReceiveData {
    id: string
}

export interface ISentChangePassword extends IResponse {
    password: string,
    confirmPassword: string
}

export interface ISentAppointmentData extends IResponse {
    otherPartyExternalUserId: string,
    interactionDateTime: string,
    interactionMode: string,
    reason: string
}

export interface ISentConnectionData extends IResponse {
    emailAddress: string,
    phoneNumber: string,
    accessLevel: string,
    customMessage: string,
    nickName: string,
    relation: string
}

