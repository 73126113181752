<h1> Fitbit Activity </h1>
<div class="config">
    <mat-form-field appearance="fill">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start date">
            <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-error *ngIf="range.controls.start.hasError('matStartDateInvalid')">Invalid start date</mat-error>
        <mat-error *ngIf="range.controls.end.hasError('matEndDateInvalid')">Invalid end date</mat-error>
    </mat-form-field>
    <mat-form-field appearance="fill">
        <mat-label>Enter RememberMe ID</mat-label>
        <input matInput [(ngModel)]="remembermeId">
      </mat-form-field>
    <div class="btn btn-success" (click)="getFitbitActivity()">
        <div (click)="alert()">Filter Data</div>
    </div>
    <div class="btn btn-info" (click)="showChart()">Show Chart</div>
    <table *ngIf="dataMeasurement">
        <thead>
            <tr>
                <th>#ID</th>
                <th>Name</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let data of dataMeasurement">
                <th>{{ data.id }}</th>
                <td>{{ data.name }}</td>
            </tr>
        </tbody>
    </table>
</div>
<br> <br> <br> <br> <br> <br>
<div id="chartdiv"></div>
<br> <br> <br> <br> <br> <br>
<div id="chartdivSecondary"></div>