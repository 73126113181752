import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationPaths } from 'src/app/models/enum';
import { AuthService } from 'src/app/services/auth.service';
import { DataService } from 'src/app/services/data.service';
import { Location } from '@angular/common';

@Component({
    selector: 'app-doctor-appointment',
    templateUrl: './doctor-appointment.component.html',
    styleUrls: ['./doctor-appointment.component.scss']
})
export class DoctorAppointmentComponent implements OnInit {

    appointmentsData: any = [];
    datesList: any = [];
    newArrayAppointments: any = [];
    orderedArrayAppointments: any = [];
    navigationPaths = NavigationPaths;

    constructor(
        private router: Router,
        public authService: AuthService,
        private dataService: DataService,
        private location: Location

    ) {

    }

    ngOnInit(): void {
        this.getAppointments();
    }

    getAppointments() {
        this.dataService.getAppointments().subscribe((res) => {
            this.appointmentsData = res;

            this.appointmentsData.forEach((appointment: any) => {
                let formatDate = new Date(appointment.interactionDateTime);
                appointment.interactionDateTime = ("0" + formatDate.getDate()).slice(-2) + "-" + ("0" + (formatDate.getMonth() + 1)).slice(-2) + "-" +
                    formatDate.getFullYear() + " " + ("0" + formatDate.getHours()).slice(-2) + ":" + ("0" + formatDate.getMinutes()).slice(-2);

                this.datesList.push(appointment.interactionDateTime.split(" ")[0]);
            })

            this.datesList = [...new Set(this.datesList)].sort((a: any, b: any) => a - b);

            this.datesList.forEach((item: any) => {
                let contentAppointment: any = { date: item, content: [] };

                this.appointmentsData.forEach((resp: any) => {
                    if (item === resp.interactionDateTime.split(" ")[0]) {
                        contentAppointment.content.push({ hour: resp.interactionDateTime.split(" ")[1], info: resp });
                    }
                    contentAppointment.content.sort((a: any, b: any) => a.hour < b.hour ? -1 : 1);
                })
                this.newArrayAppointments.push(contentAppointment);

            })
            this.newArrayAppointments.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
        });
    }

    goTo(url: string) {
        this.router.navigateByUrl(url);
    }

    goBack(): void {
        this.location.back();
      }

    checkDate(date: String) {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
        var todayString = dd + '-' + mm + '-' + yyyy;

        if (date === todayString) {
            return 'today';
        } else {
            return date;
        }
    }
}



