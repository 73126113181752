<div class="title">PROFILE</div>
<div class="main-container">
  <div class="left">
    <div class="icon"><img src="/assets/images/user.png" /></div>
    <div class="name">
      <input
        class="inputName"
        type="text"
        [(ngModel)]="this.profileData!.fullName"
        disabled="true"
      />
    </div>
    <div class="other-details">
      <div class="email">
        <mat-icon> business</mat-icon>
        <input
          class="details"
          type="text"
          [(ngModel)]="this.profileData!.email"
          disabled="true"
        />
      </div>
      <div class="call">
        <mat-icon> call</mat-icon>
        <input
          class="details"
          type="text"
          [(ngModel)]="this.profileData!.phoneNumber"
          disabled="true"
        />
      </div>
      <div class="userName">
        <mat-icon> person_pin</mat-icon>
        <input
          class="details"
          type="text"
          [(ngModel)]="this.profileData!.userName"
          disabled="true"
        />
      </div>
    </div>
    <div class="buttons">
      <div
        class="change-password"
        (click)="goTo(navigationPaths.ChangePassword)"
      >
        <div class="text">CHANGE PASSWORD</div>
      </div>
    </div>
  </div>
  <div class="right">
    <form [formGroup]="profileForm" (ngSubmit)="updateProfileData()">
      <div class="top">
        <label>Full Name</label>
        <div class="edit-area">
          <div
            *ngIf="!isModify; else cancelModify"
            class="modify-button"
            (click)="onModify()"
          >
            <mat-icon>edit</mat-icon>
            <div class="text">Modify</div>
          </div>
          <ng-template #cancelModify>
            <div class="cancel" (click)="onModify()">
              <mat-icon>cancel</mat-icon>
              <div class="text">Cancel</div>
            </div>
          </ng-template>
          <div class="confirm" *ngIf="isModify" (click)="updateProfileData()">
            <mat-icon>verified_user</mat-icon>
            <div class="text">Confirm</div>
          </div>
        </div>
      </div>
      <input
        type="text"
        formControlName="fullName"
        [attr.disabled]="!isModify ? true : null"
      />
      <label>Country</label>
      <input
        type="text"
        formControlName="country"
        [attr.disabled]="!isModify ? true : null"
      />
      <label>City</label>
      <input
        type="text"
        formControlName="city"
        [attr.disabled]="!isModify ? true : null"
      />
    </form>
  </div>
</div>
