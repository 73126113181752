import { HttpErrorResponse } from "@angular/common/http";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormControl, Validators } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { NavigationPaths } from "src/app/models/enum";
import { AuthService } from "src/app/services/auth.service";
import { DialogComponent } from "src/app/shared/dialog/dialog.component";


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  private signalOnDestroy$: Subject<boolean> = new Subject();

  navigationPaths = NavigationPaths;
  emailVerification = new FormGroup({
    email: new FormControl('', Validators.required),

  });
  constructor(private router: Router, private authService: AuthService, private dialog: MatDialog) { }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.signalOnDestroy$.next(true);
    this.signalOnDestroy$.complete();
  }

  goTo(url: string) {
    this.router.navigateByUrl(url);
  }

  sendEmailVerification() {

    if (this.emailVerification.valid) {
      const email = { email: this.emailVerification.value.email }
      this.authService.forgotPassword(email).pipe(takeUntil(this.signalOnDestroy$)).subscribe((err: any) => {
        if (!(err instanceof HttpErrorResponse)) {
          this.dialog.open(DialogComponent, {
            data: {
              description: "Check your inbox!",
              duration: 2000
            }
          });
          this.goTo(NavigationPaths.Login);
        }
      });
    }
  }
}
