// Modules
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from "@angular/material/input";
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCardModule } from '@angular/material/card';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule } from '@angular/material/radio';
import { MatTableModule } from '@angular/material/table';
import { MatDialogModule } from '@angular/material/dialog';

// Components
import { AppComponent } from './app.component';
import { PrivacyPolicyComponent, SafeHtmlPipe } from './components/privacy-policy/privacy-policy.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { FooterComponent } from './shared/footer/footer.component';
import { AssesmentsComponent } from './components/assesments/assesments.component';
import { DailyActivitiesComponent } from './components/daily-activities/daily-activities.component';
import { NavbarComponent } from './shared/navbar/navbar.component';
import { CreateDoctorAppointmentComponent } from './components/create-doctor-appointment/create-doctor-appointment.component';
import { CreateDoctorConnectionComponent } from './components/create-doctor-connection/create-doctor-connection.component';
import { DoctorApiConnectionComponent } from './components/doctor-api-connection/doctor-api-connection.component';
import { DoctorAppointmentComponent } from './components/doctor-appointment/doctor-appointment.component';
import { DoctorHomepageComponent } from './components/doctor-homepage/doctor-homepage.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';
import { CreateRecommendationComponent } from './components/create-recommendation/create-recommendation.component';
import { RecommandationsComponent } from './components/recommandations/recommandations.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { DialogComponent } from './shared/dialog/dialog.component';


// Services
import { AuthService } from './services/auth.service';

// Other imports
import { environment } from 'src/environments/environment';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AuthInterceptor } from './helpers/auth.interceptor';
import { MyClientDetailsComponent } from './components/my-client-details/my-client-details.component';



@NgModule({
    declarations: [
        AppComponent,
        SafeHtmlPipe,
        NavbarComponent,
        LoginComponent,
        RegisterComponent,
        AssesmentsComponent,
        DailyActivitiesComponent,
        RecommandationsComponent,
        DoctorApiConnectionComponent,
        PrivacyPolicyComponent,
        CreateRecommendationComponent,
        DoctorHomepageComponent,
        CreateDoctorConnectionComponent,
        DoctorAppointmentComponent,
        CreateDoctorAppointmentComponent,
        FooterComponent,
        UserProfileComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        DialogComponent,
        MyClientDetailsComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        BrowserAnimationsModule,
        MatToolbarModule,
        MatIconModule,
        MatButtonModule,
        MatMenuModule,
        MatSelectModule,
        MatFormFieldModule,
        MatInputModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatSidenavModule,
        MatButtonModule,
        MatDialogModule,
        MatSidenavModule,
        MatTableModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatSliderModule,
        MatRadioModule,
        NgbModule


    ],
    providers: [AuthService, MatDatepickerModule, DatePipe,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
