<div class="content">
  <div class="cards">
    <div class="btn btn-info orange-color" (click)="goTo(navigationPaths.DoctorCreateConnection)">
      <p>NEW CONNECTION</p>
    </div>
    <div class="btn btn-info light-green-color" (click)="goTo(navigationPaths.DoctorCreateAppoiment)">
      <p>NEW APPOINTMENT</p>
    </div>
    <div class="btn btn-info medium-green-color">
      <p>NEW INTERVENTION</p>
    </div>
    <div class="btn btn-info bright-yellow">
      <a href="http://www.webdisplay.be/rememberme/note.html">
        <p>NEW NOTE</p>
      </a>
    </div>
  </div>
  <img src="/assets/images/flower.svg" />
</div>